import React from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'

import { formatAmount, getProcessIcon, getDeliverySentToName } from "../../utilities/stringutils"
import ObjectListItem from '../ObjectList/ObjectListItem'
import OverflowSafeText from '../OverflowSafeText/OverflowSafeText'
import Img from '../Img/Img'
import './styles/historylistrow.css'

export default function HistoryListRow({ item, tableProps }) {
	if (!item) { return null }
	const { 
		task_label, 
		task_id,
		custom_task_display,
		amount, 
		receiving_team_name,
		other_team_name,
		sent_at, 
		received_at: received_at_raw, 
		process_icon, 
		process_unit,
	} = item
	const { onDelete } = tableProps
	let received_at = received_at_raw ? moment(received_at_raw).format('MM/DD/YYYY h:mm a') : '-',
		sent_to = getDeliverySentToName(receiving_team_name, other_team_name)

	return (
		<ObjectListItem className={'del-row'}>
			<div className="del-icon">
				<Img className="icon-img" src={getProcessIcon(process_icon)} />
			</div>
			<div className='del-col del-label'>
				<Link key={task_id} target="_blank" className="task" to={`/task/${task_id}`}>
					<OverflowSafeText tooltipText={task_label}>
						{custom_task_display || task_label}
					</OverflowSafeText>
				</Link>
			</div>
			<div className='del-col del-amount'>{formatAmount(amount, process_unit)}</div>
			<div className='del-col del-sent-to'>{sent_to}</div>
			<div className='del-col del-sent-at'>{moment(sent_at).format('MM/DD/YYYY h:mm a')}</div>
			<div className='del-col del-received-at'>{received_at}</div>
			{ (received_at === '-' || sent_to === 'other') &&
				<div className='del-col del-action delete' onClick={() => onDelete(item)}>Delete</div> ||
				<div className='del-col del-action delete'></div>
			}
		</ObjectListItem>
	)
}