export const MOVEMENTS = 'MOVEMENTS'
export const PRODUCTS = 'PRODUCTS'
export const RECIPES = 'RECIPES'
export const PROCESSES = 'PROCESSES'
export const TAGS = 'TAGS'
export const INVENTORIES = 'INVENTORIES'
export const DELIVERIES = 'DELIVERIES'
export const DELIVERY_HISTORY = 'DELIVERY_HISTORY'
export const PROCESS_INVENTORY = 'PROCESS_INVENTORY'
export const TASK = 'TASK'
export const TASKS = 'TASKS'
export const TASK_ANCESTORS = 'TASK_ANCESTORS'
export const TASK_DESCENDENTS = 'TASK_DESCENDENTS'
export const TASK_ATTRIBUTE = 'TASK_ATTRIBUTE'
export const MEMBERS = 'MEMBERS'
export const GOALS = 'GOALS'
export const GRAPHS = 'GRAPHS'
export const CONTACTS = 'CONTACTS'
export const ACTIVITY = 'ACTIVITY'
export const ALERTS = 'ALERTS'
export const FORMULAS = 'FORMULAS'
export const WALKTHROUGH = 'WALKTHROUGH'
export const TEAMS = 'TEAMS'
export const PRODUCTION_TRENDS = 'PRODUCTION_TRENDS'
export const MODAL = 'MODAL'
export const INVENTORY = 'INVENTORY'
export const PRODUCTION_PLANNING = 'PRODUCTION_PLANNING'
export const CSV = 'CSV'
export const GOOGLE_SHEET = 'GOOGLE_SHEET'
export const PINS = 'PINS'
