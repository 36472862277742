import React from 'react'
import AccountSectionHeader from './AccountSectionHeader'
import Card from '../Card/Card'
import Img from '../Img/Img'
import { Switch } from 'antd';

export default function FactoryOptions({ taskLabelType, onChangeTaskLabelType, teamInfo, onChangeTimeFormat}) {
	return (
		<div className="factory-options">
			<AccountSectionHeader title="Factory Options" />
			<Card>
				<div className="account-integration">
					<div className="integration-info">
						<div>
							<span>Default Printed Label Style</span>
						</div>
					</div>
					<div className="integration-detail">
						<span>Click to select the default style of label you'd like printed whenever tasks labels are printed on the mobile app.</span>
					</div>
					<SelectableLabelImages taskLabelType={taskLabelType} onChange={onChangeTaskLabelType}/>
					<div className="integration-info">
						<div>
							<span>Set Time Format</span>
						</div>
					</div>
					<RenderTimeFormatSwitch teamInfo={teamInfo} onClick={onChangeTimeFormat}/>
				</div>
			</Card>
		</div>
	)
}

function RenderTimeFormatSwitch({teamInfo, onClick}) {
	let time_format = teamInfo === 'n'
	return (
		<div>
			<span className="integration-detail"> Use 12 hour time format </span>				
			<Switch 
				checked = {time_format}
				onChange = {() => onClick(teamInfo)}
			/>
		</div>
	)
}

export function SelectableLabelImages({ taskLabelType, onChange, style }) {
	const labelImages = ['task-label-type-0', 'task-label-type-1', 'task-label-type-2', 'custom-task-label-type-0']
	return (
		<div className="selectable-label-images" style={style}>
			{labelImages.map((img, i) =>
				<div
					key={i}
					className={`label-image ${taskLabelType === i ? 'selected' : ''}`}
					onClick={() => onChange(i)}
				>
					<Img src={img} className="icon"/>
				</div>
			)}
		</div>
	)
}

