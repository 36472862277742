import React from 'react'
import { connect } from 'react-redux'

import InventoryIcon from './InventoryIcon'
import InventoryDrawerAdjustedAmount from './InventoryDrawerAdjustedAmount'
import AdjustmentHistory from './AdjustmentHistory'
import * as actions from './InventoryActions'
import * as adjustmentActions from './AdjustmentActions'
import { dateToUTCString } from '../../utilities/dateutils'
import { formatAmount } from '../../utilities/stringutils'
import { inventoryName } from './inventoryUtils'
import Loading from '../Loading/Loading'
import './styles/inventorydrawer.css'

class InventoryDrawer extends React.Component {
	constructor(props) {
		super(props)
		this.state = { isAddingAdjustment: false }

		this.handleToggleAddAdjustment = this.handleToggleAddAdjustment.bind(this)
		this.handleSaveAdjustment = this.handleSaveAdjustment.bind(this)
	}

	fetchHistory(teamId, processId, productId, date) {
		this.props.dispatch(actions.fetchInventoryHistory(teamId, processId, productId, date))
	}

	fetchAggregate() {
		const { filters, ordering } = this.props
		const params = {
			date: dateToUTCString(filters.date),  
			ordering 
		}
		if (filters.selectedCategories.length) {
			params.category_types = filters.selectedCategories.join(',')
		}
		if (filters.selectedProcesses.length) {
			params.process_types = filters.selectedProcesses.join(',')
		}
		if (filters.selectedProducts.length) {
			params.product_types = filters.selectedProducts.join(',')
		}
		if (filters.aggregateProducts) {
			params.aggregate_products = 'true'
		}

		this.props.dispatch(actions.fetchAggregate(params))
	}

	render() {
		let { ui, filters, selectedInventory } = this.props
		if (filters.aggregateProducts || !selectedInventory || !selectedInventory.history) {
			return null
		}

		return (
			<div className="inventory-drawer">
				<DrawerTitle inventory={selectedInventory}
				             showCancel={this.state.isAddingAdjustment}
				             onCancel={this.handleToggleAddAdjustment}
				/>
				{this.state.isAddingAdjustment ?
					<InventoryDrawerAdjustedAmount inventory={selectedInventory} onSaveAdjustment={this.handleSaveAdjustment} /> :
					<AddAdjustment onClick={this.handleToggleAddAdjustment} />
				}
				<Loading isFetchingData={ui.isFetchingHistory || ui.isAdjusting}>
					<AdjustmentHistory history={selectedInventory.history} unit={selectedInventory.process_type.unit} />
				</Loading>
			</div>
		)
	}

	handleToggleAddAdjustment() {
		this.setState({ isAddingAdjustment: !this.state.isAddingAdjustment })
	}

	handleSaveAdjustment(amount, explanation) {
		this.handleToggleAddAdjustment()
		this.props.dispatch(adjustmentActions.requestCreateAdjustment(
			this.props.userProfileId,
			this.props.selectedInventory.process_type.id,
			this.props.selectedInventory.product_types[0].id,
			amount,
			explanation
		)).then(() => {
			this.fetchHistory(this.props.teamId, this.props.selectedInventory.process_type.id, this.props.selectedInventory.product_types[0].id, this.props.filters.date)
			this.fetchAggregate()
		})
	}
}

const mapStateToProps = (state/*, props*/) => {
	return {
		userProfileId: state.users.data[state.users.ui.activeUser].user.id,
		ui: state.inventory.ui,
	}
}

export default connect(mapStateToProps)(InventoryDrawer)

function AddAdjustment({ onClick }) {
	return (
		<div className="add-adjustment" onClick={onClick}>
			I need to update my stock count
		</div>
	)
}

function DrawerTitle({ inventory, showCancel, onCancel }) {
	let { adjusted_amount, process_type } = inventory
	return (
		<div className="inv-drawer-title">
			<InventoryIcon icon={process_type.icon} outerSize={36} innerSize={24} />
			<div>
				<span>{inventoryName(inventory)}</span>
				<div>{formatAmount(adjusted_amount, process_type.unit)}</div>
			</div>
			{showCancel && <div className="cancel" onClick={onCancel}>Cancel</div>}
		</div>
	)
}


