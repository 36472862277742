import React from 'react'
import { connect } from 'react-redux'
import { Select, DatePicker } from 'antd'
import Spinner from 'react-spinkit'
import moment from 'moment'

import Checkbox from '../Inputs/Checkbox'
import * as processesActions from '../Processes/ProcessesActions.jsx'
import * as productsActions from '../Products/ProductsActions.jsx'
import * as tagActions from '../Tags/TagActions'
import { formatCost } from './inventoryUtils'
import { processProductFilter, formatOption } from '../../utilities/filters'
import { toUTCString } from '../../utilities/dateutils'
import { RM, WIP, FG, CATEGORY_NAME } from '../../utilities/constants'
import './styles/inventoryfilters.css'
import * as actions from './InventoryActions'


class InventoryFilters extends React.Component {
	constructor(props) {
		super(props)

		this.handleDateChange = this.handleDateChange.bind(this)
		this.handleProcessTypeChange = this.handleProcessTypeChange.bind(this)
		this.handleProductTypeChange = this.handleProductTypeChange.bind(this)
		this.handleCategoryTypeChange = this.handleCategoryTypeChange.bind(this)
		this.handleTagTypeChange = this.handleTagTypeChange.bind(this)
		this.handleAggregateProductsChange = this.handleAggregateProductsChange.bind(this)
		this.handleShowTrashed = this.hangleShowTrashed.bind(this)
	}

	componentDidMount() {
		let { filters } = this.props
		this.props.dispatch(processesActions.fetchProcesses({ showTrashed: filters.showTrashed }))
		this.props.dispatch(productsActions.fetchProducts({ showTrashed: filters.showTrashed }))
		this.props.dispatch(tagActions.fetchTags())
	}

	componentDidUpdate(prevProps) {
		if (this.props.data === prevProps.data) {
			return
		}

		if (this.props.data.length == 0) {
			return
		}

		let liveProductsCodeList = typeof this.props.products !== 'undefined' ? new Set(this.props.products.map(item => item.code)) : new Set()
		let liveProcessesCodeList = typeof this.props.processes != 'undefined' ? new Set(this.props.processes.map(item => item.code)) : new Set()
		let tableData = this.props.data
		console.log("%d entries, %d non-archived products, %d non-archived processes", tableData.length,
			liveProductsCodeList.size, liveProcessesCodeList.size)

		// we only filter the lastest fetched data
		const prevDataLength = prevProps.data.length
		const thisDataLength = this.props.data.length

		// only filter if there's new data from API. Do not filter after data update from setting cleaned data
		if (thisDataLength <= prevDataLength) {
			return
		}

		const index = -1 * (thisDataLength - prevDataLength)  // count from end of array
		let newlyFetchedData = tableData.slice(index)
		let oldCleanedData = tableData.slice(0, index)

		// clean new data and join it to old cleaned data
		let cleanedResults = this.filterArchivedProcessesAndProducts(newlyFetchedData, liveProductsCodeList, liveProcessesCodeList)
		let filteredResults = oldCleanedData.concat(cleanedResults)

		// need to update page if items got filtered out
		if (filteredResults.length !== tableData.length) {
			this.props.dispatch(actions.cleanArchivedProductsAndProcesses({ filteredResults }))
			console.log("Replaced data with filtered inventory")
		}
  }

	/* Filters out archived Products and Processes from Inventory table data */
	filterArchivedProcessesAndProducts(tableData, liveProductsCodeList, liveProcessesCodeList) {
		var result = []
		for (const item of tableData) {
			var product_code = item.product_types[0].code
			var process_code = item.process_type.code
			if (liveProductsCodeList.has(product_code) && liveProcessesCodeList.has(process_code)) {
				result.push(item)
			}
		}
		return result
	}

	render() {
		const { filters, products, processes, tags, isFetchingAggregateData, aggregateData } = this.props
		const categories = [
			{ name: CATEGORY_NAME[RM], code: RM },
			{ name: CATEGORY_NAME[WIP], code: WIP },
			{ name: CATEGORY_NAME[FG], code: FG },
		]
		if (this.props.isFetchingData)
			return null

		return (
			<div className='inventory-filters'>
				<div className='row'>
					<DatePicker 
						format="YYYY-MM-DD"
						showTime
						allowClear={false}
						value={moment.utc(filters.date, 'YYYY-MM-DD-HH-mm-ss-SSSSSS').local()} 
						onChange={this.handleDateChange} 
					/>
					{ processes.length > 0 && <Select
						mode="multiple"
						value={filters.selectedProcesses}
						allowClear
						placeholder="Filter processes"
						filterOption={processProductFilter}
						onChange={this.handleProcessTypeChange}
					>
						{ processes.map(p => 
							<Select.Option key={p.id} data={p}>{formatOption(p)}</Select.Option>
						) }
					</Select>}
					{ products.length > 0 && <Select
						mode="multiple"
						value={filters.selectedProducts}
						allowClear
						placeholder="Filter products"
						filterOption={processProductFilter}
						onChange={this.handleProductTypeChange}
					>
						{ products.map(p => 
							<Select.Option key={p.id} data={p}>{formatOption(p)}</Select.Option>
						) }
					</Select>}
				</div>
				<div className='row'>
					{ categories.length > 0 && <Select
						mode="multiple"
						value={filters.selectedCategories}
						allowClear
						placeholder="Filter categories"
						filterOption={processProductFilter}
						onChange={this.handleCategoryTypeChange}
					>
						{ categories.map(c => 
							<Select.Option key={c.code} data={c}>{c.name}</Select.Option>
						) }
					</Select>}
					{ tags.length > 0 && <Select
						mode="multiple"
						value={filters.selectedTags}
						allowClear
						placeholder="Filter tags"
						onChange={this.handleTagTypeChange}
					>
						{ tags.map(t => 
							<Select.Option key={t.name} data={t}>{t.name}</Select.Option>
						) }
					</Select>}
					<div className="checkboxes">
						<Checkbox
							label="Aggregate across product types"
							checked={filters.aggregateProducts}
							onChange={this.handleAggregateProductsChange}
						/>
						<Checkbox
							label="Search archived products and processes"
							checked={filters.showTrashed}
							onChange={this.handleShowTrashed}
						/>
					</div>
				</div>
				<div className='row'>
					<AggregateCategoryBoxes data={aggregateData} isFetchingData={isFetchingAggregateData}/>
				</div>
			</div>
		)

	}

	handleDateChange(date) {
		let utcDate = toUTCString(date)
		this.props.onFilterChange({ ...this.props.filters, date: utcDate })
	}

	handleProcessTypeChange(selectedProcesses) {
		this.props.onFilterChange({ ...this.props.filters, selectedProcesses })
	}

	handleProductTypeChange(selectedProducts) {
		this.props.onFilterChange({ ...this.props.filters, selectedProducts })
	}

	handleCategoryTypeChange(selectedCategories) {
		this.props.onFilterChange({ ...this.props.filters, selectedCategories })
	}

	handleTagTypeChange(selectedTags) {
		this.props.onFilterChange({ ...this.props.filters, selectedTags })
	}

	handleAggregateProductsChange(evt) {
		this.props.onFilterChange({ ...this.props.filters, aggregateProducts: evt.target.checked })
	}

	hangleShowTrashed(evt) {
		let showTrashed = evt.target.checked
		this.props.onFilterChange({ ...this.props.filters, showTrashed })
		
		this.props.dispatch(processesActions.fetchProcesses({ showTrashed }))
		this.props.dispatch(productsActions.fetchProducts({ showTrashed }))
	}
}

function AggregateCategoryBoxes({ data, isFetchingData }) {
	const processed = processData(data)
	
	return (
		<div className='category-boxes-container'>
			<div className="category-box">
				<div className='category-box-title'>{CATEGORY_NAME['rm']}</div>
				<div className='category-box-cost'>
					{ !isFetchingData && formatCost(processed['rm'])}
					{ isFetchingData && <Spinner fadeIn="quarter" name="wave" color="grey" />}
				</div>
			</div>
			<div className="category-box">
				<div className='category-box-title'>{CATEGORY_NAME['wip']}</div>
				<div className='category-box-cost'>
					{ !isFetchingData && formatCost(processed['wip'])}
					{ isFetchingData && <Spinner fadeIn="quarter" name="wave" color="grey" />}
				</div>
			</div>
			<div className="category-box">
				<div className='category-box-title'>{CATEGORY_NAME['fg']}</div>
				<div className='category-box-cost'>
					{ !isFetchingData && formatCost(processed['fg'])}
					{ isFetchingData && <Spinner fadeIn="quarter" name="wave" color="grey" />}
				</div>
			</div>
		</div>
	)
}

function processData(data) {
	const processed = { 'rm': 0, 'wip': 0, 'fg': 0 }
	if (data) {
		data.forEach(dataItem => {
			const { category, adjusted_cost } = dataItem
			processed[category] += adjusted_cost
		})
	}
	return processed
}

const mapStateToProps = (state/*, props*/) => {
	const isFetchingData = state.processes.ui.isFetchingData || state.products.ui.isFetchingData
	const categories = [
		{ name: CATEGORY_NAME[RM], code: RM },
		{ name: CATEGORY_NAME[WIP], code: WIP },
		{ name: CATEGORY_NAME[FG], code: FG },
	]
	return {
		processes: state.processes.data,
		products: state.products.data,
		tags: state.tags.data,
		categories,
		isFetchingData: isFetchingData,
		isFetchingAggregateData: state.inventory.ui.isFetchingAggregateData
	}
}

export default connect(mapStateToProps)(InventoryFilters)

