import React from 'react'

import { formatAmount, getProcessIcon, getDeliverySentToName } from "../../utilities/stringutils"
import ObjectListItem from '../ObjectList/ObjectListItem'
import OverflowSafeText from '../OverflowSafeText/OverflowSafeText'
import Img from '../Img/Img'
import './styles/incominglistrow.css'

export default function AggregateHistoryListRow({ item }) {
	if (!item) { return null }
	const { 
		process_type,
		product_type,
		amount,
		receiving_team_name,
		other_team_name
	} = item
	let label = `${process_type.name} ${product_type.name}`,
		sent_to = getDeliverySentToName(receiving_team_name, other_team_name)

	return (
		<ObjectListItem className={'del-row'}>
			<div className="del-icon">
				<Img className="icon-img" src={getProcessIcon(process_type.icon)} />
			</div>
			<div className='del-col del-label'>
				<OverflowSafeText tooltipText={label}>
					{label}
				</OverflowSafeText>
			</div>
			<div className='del-col del-amount'>{formatAmount(amount, process_type.unit)}</div>
			<div className='del-col del-sent-to'>{sent_to}</div>
		</ObjectListItem>
	)
}