import React from 'react'
import { connect } from 'react-redux'
import { Modal } from 'antd'
import moment from 'moment'

import * as actions from '../DeliveriesPage/DeliveriesActions'
import IncomingListRow from './IncomingListRow'
import ObjectList from '../ObjectList/ObjectList'
import ObjectListHeader from '../ObjectList/ObjectListHeader'
import Table from '../Table/Table'
import './styles/deliveriespage.css'
import './styles/incominglistrow.css'


class IncomingDeliveries extends React.Component {
	constructor(props) {
		super(props)
		this.state = {}

		this.handleAcceptDelivery = this.handleAcceptDelivery.bind(this)
		this.handleRejectDelivery = this.handleRejectDelivery.bind(this)
		this.handleConfirmRejectDelivery = this.handleConfirmRejectDelivery.bind(this)
	}

	render() {
		const { isFetchingData, data } = this.props
		return (
			<ObjectList className='incoming-deliveries' isFetchingData={isFetchingData}>
				<div className='content-title'>Incoming Deliveries</div>
				{ data && !data.length && 
					<div className='empty-screen-text'>No incoming deliveries.</div>
				}
				{ data && !!data.length &&
					<Table 
						data={data}
						TitleRow={this.renderIncomingTableHeader}
						Row={IncomingListRow}
						isFetchingData={isFetchingData}
						onAccept={this.handleAcceptDelivery}
						onReject={this.handleRejectDelivery}
					/>
				}
			</ObjectList>
		)
	}

	renderIncomingTableHeader() {
		const columns = [
			{ title: '', className: 'del-icon', field: null },
			{ title: 'Task', className: 'del-task', field: null },
			{ title: 'Amount', className: 'del-amount', field: null },
			{ title: 'Sent By', className: 'del-sent-by', field: null },
			{ title: 'Sent', className: 'del-sent-at', field: null },
			{ title: 'Actions', className: 'del-action', field: null },
		]
		return (
			<ObjectListHeader columns={columns} ordering=''/>
		)
	}

	handleAcceptDelivery(delivery) {
		let index = this.getDeliveryIndex(delivery),
			params = {
				received_at: moment.utc().format('YYYY-MM-DD HH:mm')
			}
		this.props.dispatch(actions.editDelivery(delivery, params, index))
	}

	handleRejectDelivery(delivery) {
		Modal.confirm({
			title: 'Cancel delivery',
			content: "Are you sure you want to reject this delivery?",
			okText: 'Yes, I\'m sure',
			okType: 'danger',
			onOk: () => this.handleConfirmRejectDelivery(delivery),
			onCancel: () => {}
		})
	}

	handleConfirmRejectDelivery(delivery) {
		let index = this.getDeliveryIndex(delivery),
			params = { is_rejected: true }
		this.props.dispatch(actions.editDelivery(delivery, params, index))
	}

	getDeliveryIndex(delivery) {
		return this.props.deliveries.findIndex(d => d.id === delivery.id)
	}
}

const mapStateToProps = (state, props) => {
	let teamID = state.users.data[state.users.ui.activeUser].user.team
	return {
		isFetchingData: state.deliveries.ui.isFetchingData,
		deliveries: state.deliveries.data,
		data: state.deliveries.data.filter(d => {
			// data is the deliveries that will be displayed
			return String(d.receiving_team) === teamID && !d.received_at && !d.is_rejected
		})
	}
}

export default connect(mapStateToProps)(IncomingDeliveries)