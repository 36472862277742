import React from 'react'
import { connect } from 'react-redux'

import * as actions from '../DeliveriesPage/DeliveriesActions'
import SectionTabs from '../SectionTabs/SectionTabs'
import IncomingDeliveries from './IncomingDeliveries'
import OutgoingDeliveries from './OutgoingDeliveries'
import DeliveryHistory from './DeliveryHistory'
import './styles/deliveriespage.css'
import './styles/incominglistrow.css'


class DeliveriesPage extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			view: props.location.search ? 'History' : 'Deliveries'
		}

		this.handleClickTab = this.handleClickTab.bind(this)
	}

	componentDidMount() {
		this.props.dispatch(actions.fetchDeliveries())
	}

	render() {
		return (
			<div className='deliveries-page'>
				<SectionTabs activeOption={this.state.view} options={['Deliveries', 'History']} onClickTab={this.handleClickTab} />
				{ this.state.view === 'Deliveries' &&
					<div>
						<IncomingDeliveries />
						<OutgoingDeliveries />
					</div>
				}
				{ this.state.view === 'History' &&
					<DeliveryHistory 
						location={this.props.location}
						history={this.props.history}
					/>
				}
			</div>
		)
	}

	handleClickTab(view) {
		// clear url search params if deliveries tab is selected
		if (view === 'Deliveries') {
			this.props.dispatch(actions.fetchDeliveries())
			this.props.history.push({ search: "" })
		}
		this.setState({ view })
	}
}

const mapStateToProps = (state, props) => {
	return {}
}

export default connect(mapStateToProps)(DeliveriesPage)