import React from 'react'
import './styles/taskform.css'
import './styles/peripherals.css'
import moment from 'moment'
import { DatePicker } from 'antd'
import { Peripherals } from './TaskForm'
import { isValidISODate } from '../../utilities/dateutils'

export default class DateAttribute extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			draftValue: props.value,
		}
		this.handleSave = this.handleSave.bind(this)
	}
	
	handleSave(value) {
		let new_date = moment(value).utc().format("YYYY-MM-DD")
		if(this.state.draftValue !== new_date) {
			this.setState({ draftValue: new_date })
			this.handleSaveWrapper(new_date)
		}
	}
	
	handleSaveWrapper(v) {
		this.props.onSave(v)
	}
	
	render() {
		const { draftValue } = this.state
		
		const defaultValue = isValidISODate(draftValue) ? moment(draftValue) : undefined
		const placeholder = isValidISODate(draftValue) ? 'Select a Date' : draftValue

		return (
			<div className="input-container">
				<DatePicker
					allowClear={false}
					format='MMMM DD, YYYY'
					placeholder={placeholder}
					defaultValue={defaultValue}
					onChange={this.handleSave}
					size="large"
				/>
				<Peripherals {...this.props} onRetry={this.handleSave} />
			</div>
		)
	}
}
