import React from 'react'
import Datepicker from '../Datepicker/Datepicker.jsx'
import { Checkbox, Radio } from 'antd'
import './styles/deliveryhistoryfilters.css'

export default class DeliveryHistoryFilters extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			isDownloading: false
		}

		this.handleDatesChange = this.handleDatesChange.bind(this)
		this.handleDirectionChange = this.handleDirectionChange.bind(this)
		this.handleAggregateChange = this.handleAggregateChange.bind(this)
	}

	handleDatesChange(dates) {
		this.props.onFilterChange({ ...this.props.filters, dates })
	}
	
	handleDirectionChange(e) {
		this.props.onFilterChange({ ...this.props.filters, direction: e.target.value })
	}

	handleAggregateChange(e) {
		this.props.onFilterChange({ ...this.props.filters, aggregate: e.target.checked })
	}

	render() {
		const { filters } = this.props
		if (!filters.dates.start || !filters.dates.end) {
			return null
		}
		const options = [
			{ label: "Sent", value: "sent" },
			{ label: "Received", value: "received"}
		]

		const RadioGroup = Radio.Group

		return (
			<div className="delivery-history-filters">
				<div className="row">
					<Datepicker 
						initialDates={filters.dates} 
						onChange={this.handleDatesChange} 
					/>
					<RadioGroup 
						options={options} 
						value={filters.direction} 
						onChange={this.handleDirectionChange} 
					/>
				</div>
				<div className="row">
					<Checkbox checked={filters.aggregate} onChange={this.handleAggregateChange}>Aggregate</Checkbox>
				</div>
			</div>
		)
	}
}

