import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import { toUTCString } from '../../utilities/dateutils'
import * as actions from './InventoryActions'
import ApplicationSectionHeader from '../Application/ApplicationSectionHeader'
import InventoryDrawer from './InventoryDrawer'
import PaginatedTable from '../PaginatedTable/PaginatedTable'
import InventoryFilters from './InventoryFilters'
import InventoryListRow from './InventoryListRow'
import ObjectList from '../ObjectList/ObjectList'
import ObjectListHeader from '../ObjectList/ObjectListHeader'
import Loading from '../Loading/Loading'
import './styles/inventory.css'

export class Inventory extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			selectedProcesses: [],
			selectedProducts: [],
			selectedCategories: [],
			selectedTags: [],
			aggregateProducts: false,
			ordering: 'creating_task__process_type'
		}

		this.renderTableHeader = this.renderTableHeader.bind(this)

		this.handleReorder = this.handleReorder.bind(this)
		this.handleSelectRow = this.handleSelectRow.bind(this)
		this.handlePagination = this.handlePagination.bind(this)
		this.handleFilterChange = this.handleFilterChange.bind(this)
		this.fetchInventory = this.fetchInventory.bind(this)
	}

	componentDidMount() {
		this.setDefaultFilters()
	}

	render() {
		let { ui, selectedInventory } = this.props
		const filters = this.getFilters()
		return (
			<div className="inventory-container">
				<ApplicationSectionHeader>Inventory</ApplicationSectionHeader>

				<div className="inventory-content">
					<div className="inventory-list-container">
						<InventoryFilters
							filters={filters}
							data={this.props.data}
							aggregateData={this.props.aggregateData}
							onFilterChange={this.handleFilterChange}
						/>
						<Loading isFetchingData={ui.isFetchingData}>
							<ObjectList>
								<PaginatedTable
									ui={this.props.ui}
									data={this.props.data}
									TitleRow={this.renderTableHeader}
									onClick={this.handleSelectRow}
									onPagination={this.handlePagination}
									Row={InventoryListRow}
								/>
							</ObjectList>
						</Loading>
					</div>
					<InventoryDrawer
						selectedInventory={selectedInventory}
						filters={filters} 
						ordering={this.state.ordering}
					/>
				</div>
				{this.renderHelp()}
			</div>
		)
	}

	renderTableHeader() {
		const columns = [
			{ title: null, className: 'inv-icon', field: null },
			{ title: 'Inventory Unit', className: 'inv-title', field: null },
			{ title: 'Category', className: 'inv-category', field: null },
			{ title: 'Code', className: 'inv-code', field: null },
			{ title: 'Amount', className: 'inv-amount', field: null },
			{ title: 'Dollar value', className: 'inv-cost', field: null },
		]
		return (
			<ObjectListHeader columns={columns} onReorder={this.handleReorder} ordering={this.state.ordering} />
		)
	}
	
	renderHelp() {
		return (
			<div className="inventory-page-help-container">
				<div className="inventory-page-help"
						 onClick={() => window.open("https://polymer.helpscoutdocs.com/article/21-understanding-and-setting-costs-for-tasks", '_blank')}>
					<div className="inventory-page-help-header">Track value like never before</div>
					<div>
						<span>Track cost for every item in your factory, and understand how value is distribute across your production line. </span>
						<span className="inventory-page-help-link">
							Learn how to supercharge your production insights now.
					</span>
						<span className="inventory-page-forward">  <i
							className="material-icons inventory-page-forward-i">arrow_forward</i></span>
					</div>
				</div>
			</div>
		)
	}

	handleReorder(ordering) {
		this.setState({ordering: ordering}, this.fetchInventory)
	}

	handleSelectRow(i) {
		this.props.dispatch(actions.selectInventory(i))
		let filters = this.getFilters()
		let { process_type, product_types } = this.props.data[i]

		if (product_types.length === 1) {
			this.props.dispatch(actions.fetchInventoryHistory(this.props.teamID, process_type.id, product_types[0].id, filters.date))
		}
	}

	handlePagination(direction) {
		let { ui, dispatch } = this.props
		if (direction === 1 && ui.more) {
			dispatch(actions.fetchMoreInventory(ui.more))
		}
		dispatch(actions.pageInventory(direction))
	}

	handleFilterChange(filters) {
		this.setState(filters)
		this.getInventory(filters)

		const qs = new URLSearchParams(this.props.location.search)
		qs.set('date', filters.date)
		qs.set('selectedProcesses', filters.selectedProcesses.join(','))
		qs.set('selectedProducts', filters.selectedProducts.join(','))
		qs.set('selectedCategories', filters.selectedCategories.join(','))
		qs.set('selectedTags', filters.selectedTags.join(','))
		qs.set('aggregateProducts', String(filters.aggregateProducts))
		qs.set('showTrashed', String(filters.showTrashed))
		this.props.history.push({ search: qs.toString() })
	}

	setDefaultFilters() {
		this.handleFilterChange(this.getFilters())
	}

	fetchInventory() {
		this.getInventory(this.getFilters())
	}

	getInventory(filters) {
		const params = {
			date: filters.date, 
			ordering: this.state.ordering
		}
		if (filters.selectedProcesses.length) {
			params.process_types = filters.selectedProcesses.join(',')
		}
		if (filters.selectedProducts.length) {
			params.product_types = filters.selectedProducts.join(',')
		}
		if (filters.selectedCategories.length) {
			params.category_types = filters.selectedCategories.join(',')
		}
		if (filters.selectedTags.length) {
			params.tags = filters.selectedTags.join(',')
		}
		if (filters.aggregateProducts) {
			params.aggregate_products = 'true'
		}

		this.props.dispatch(actions.fetchInventory(params))
		this.props.dispatch(actions.fetchAggregate(params))
	}

	getFilters() {
		const qs = new URLSearchParams(this.props.location.search)
		return {
			date: qs.get('date') || toUTCString(moment().endOf('day')),
			selectedProcesses: qs.get('selectedProcesses') ? qs.get('selectedProcesses').split(',') : [],
			selectedProducts: qs.get('selectedProducts') ? qs.get('selectedProducts').split(',') : [],
			selectedCategories: qs.get('selectedCategories') ? qs.get('selectedCategories').split(',') : [],
			selectedTags: qs.get('selectedTags') ? qs.get('selectedTags').split(',') : [],
			aggregateProducts: qs.get('aggregateProducts') === 'true',
			showTrashed: qs.get('showTrashed') === 'true'
		}
	}
}

const mapStateToProps = (state/*, props*/) => {
	let data = state.inventory.data
	let ui = state.inventory.ui
	return {
		teamID: state.users.data[state.users.ui.activeUser] && state.users.data[state.users.ui.activeUser].user.team,
		selectedInventory: data[ui.selectedItem],
		aggregateData: state.inventory.aggregateData,
		data, ui
	}
}

export default connect(mapStateToProps)(Inventory)
