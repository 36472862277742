import { DELIVERIES, DELIVERY_HISTORY } from '../../reducers/ReducerTypes'
import * as actions from '../../reducers/APIDataActions'

export function fetchDeliveries(team) {
    const request = {
		url: '/ics/deliveries/',
		query: { team, exclude_completed: true }
    }
    return actions.fetch(DELIVERIES, request, null, res => res.body)
}

export function fetchDeliveryHistory(params) {
    const request = {
		url: '/ics/deliveries/history/',
		query: params
    }
    return actions.fetch(DELIVERY_HISTORY, request, null, res => res.body)
}

export function editDelivery(delivery, params, index) {
    const request = {
		url: `/ics/deliveries/${delivery.id}/`,
		data: params
    }
    return actions.patchEdit(DELIVERIES, request, index, res => res.body)
}

export function deleteDelivery(delivery, params, index) {
    const request = {
        url: `/ics/deliveries/${delivery.id}`,
        data: params
    }
    return actions.hardDelete(DELIVERIES, request, index)
}

export function deleteDeliveryHistory(delivery, params, index) {
    const request = {
        url: `/ics/deliveries/${delivery.id}`,
        data: params
    }
    return actions.hardDelete(DELIVERY_HISTORY, request, index)
}