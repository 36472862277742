import React from 'react';
import './styles/passwordreset.css'
import WalkthroughFrame from '../Walkthrough/WalkthroughFrame'
import Card from '../Card/Card'
import { Link } from 'react-router-dom'


/* Password reset instructions */
class PasswordReset extends React.Component {
  render() {
    return (
      <WalkthroughFrame>
        <div className='reset-box'>
        <Card>
          <p id="info-message" >
           Please check your inbox and search for an email titled 
           "<b>You're invited to team <i>(insert your team name)</i> on Polymer!</b>"
           For example: "You're invited to team valencia on Polymer!"
          <br></br><br></br>
            You may click the link in this email to reset your password.
          <br></br><br></br>
          <Link to="/login"> Go back to login</Link>
          </p>
        </Card>
        </div>
      </WalkthroughFrame>
    );
  }
}
export default PasswordReset;