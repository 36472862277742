import React from 'react'
import './styles/sectiontabs.css'

export default function SectionTabs({ activeOption, options, onClickTab } ) {
	return (
		<div className="section-tabs">
			{ options && options.map( (option, i) => {
				const isActive = activeOption === option
				return <div key={`tab-${i}-${option}`} className={`tab ${isActive ? 'active' : ''}`} onClick={() => onClickTab(option)}>{option}</div>
			}) }
		</div>
	)
}