import React from 'react'
import { connect } from 'react-redux'
import { Modal } from 'antd'

import * as actions from '../DeliveriesPage/DeliveriesActions'
import OutgoingListRow from './OutgoingListRow'
import ObjectList from '../ObjectList/ObjectList'
import ObjectListHeader from '../ObjectList/ObjectListHeader'
import Table from '../Table/Table'
import './styles/deliveriespage.css'
import './styles/incominglistrow.css'


class OutgoingDeliveries extends React.Component {
	constructor(props) {
		super(props)
		this.state = {}

		this.handleCancelDelivery = this.handleCancelDelivery.bind(this)
	}

	render() {
		const { isFetchingData, data } = this.props
		return (
			<ObjectList className='outgoing-deliveries' isFetchingData={isFetchingData}>
				<div className='content-title'>Outgoing Deliveries</div>
				{ data && !data.length && 
					<div className='empty-screen-text'>No outgoing deliveries.</div>
				}
				{ data && !!data.length &&
					<Table 
						data={data}
						TitleRow={this.renderOutgoingTableHeader}
						Row={OutgoingListRow}
						isFetchingData={isFetchingData}
						onCancel={this.handleCancelDelivery}
					/>
				}
			</ObjectList>
		)
	}

	renderOutgoingTableHeader() {
		const columns = [
			{ title: '', className: 'del-icon', field: null },
			{ title: 'Task', className: 'del-task', field: null },
			{ title: 'Amount', className: 'del-amount', field: null },
			{ title: 'Sent To', className: 'del-sent-to', field: null },
			{ title: 'Sent', className: 'del-sent-at', field: null },
			{ title: 'Action', className: 'del-action', field: null },
		]
		return (
			<ObjectListHeader columns={columns} ordering=''/>
		)
	}

	handleCancelDelivery(delivery) {
		Modal.confirm({
			title: 'Cancel delivery',
			content: "Are you sure you want to cancel this delivery?",
			okText: 'Yes, I\'m sure',
			okType: 'danger',
			onOk: () => this.handleConfirmCancelDelivery(delivery),
			onCancel: () => {}
		})
	}

	handleConfirmCancelDelivery(delivery) {
		let index = this.getDeliveryIndex(delivery)
		this.props.dispatch(actions.deleteDelivery(delivery, {}, index))
	}

	getDeliveryIndex(delivery) {
		return this.props.data.findIndex(d => d.id === delivery.id)
	}
}

const mapStateToProps = (state, props) => {
	let teamID = state.users.data[state.users.ui.activeUser].user.team
	return {
		isFetchingData: state.deliveries.ui.isFetchingData,
		data: state.deliveries.data.filter(d => {
			return String(d.sending_team) === teamID && !d.received_at && !d.is_rejected
		})
	}
}

export default connect(mapStateToProps)(OutgoingDeliveries)