import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { Modal } from 'antd'

import * as actions from '../DeliveriesPage/DeliveriesActions'
import { dateToUTCString } from '../../utilities/dateutils'
import DeliveryHistoryFilters from './DeliveryHistoryFilters'
import HistoryListRow from './HistoryListRow'
import AggregateHistoryListRow from './AggregateHistoryListRow'
import ObjectList from '../ObjectList/ObjectList'
import ObjectListHeader from '../ObjectList/ObjectListHeader'
import Table from '../Table/Table'
import './styles/deliveryhistory.css'


class DeliveryHistory extends React.Component {
	constructor(props) {
		super(props)
		this.state = {}

		this.handleFilterChange = this.handleFilterChange.bind(this)
		this.handleDeleteDelivery = this.handleDeleteDelivery.bind(this)
	}

	render() {
		const { isFetchingData, data } = this.props
		const filters = this.getFilters()

		if (!filters.dates.start) {
			this.setDefaultFilters()
		}
		return (
			<div className='delivery-history-container'>
				<DeliveryHistoryFilters
					filters={filters}
					onFilterChange={this.handleFilterChange}
				/>
				<ObjectList className='delivery-history' isFetchingData={isFetchingData}>
					{ data && !data.length && 
						<div className='empty-screen-text'>No results found.</div>
					}
					{ data && !!data.length &&
						<Table 
							data={data}
							TitleRow={filters.aggregate ? this.renderAggregateTableHeader : this.renderTableHeader}
							Row={filters.aggregate ? AggregateHistoryListRow : HistoryListRow}
							isFetchingData={isFetchingData}
							onDelete={this.handleDeleteDelivery}
						/>
					}
				</ObjectList>
			</div>
		)
	}

	renderTableHeader() {
		const columns = [
			{ title: '', className: 'del-icon', field: null },
			{ title: 'Task', className: 'del-label', field: null },
			{ title: 'Amount', className: 'del-amount', field: null },
			{ title: 'Sent To', className: 'del-sent-to', field: null },
			{ title: 'Sent', className: 'del-sent-at', field: null },
			{ title: 'Received', className: 'del-received-at', field: null },
			{ title: '', className: 'del-action', field: null },
		]
		return (
			<ObjectListHeader columns={columns} ordering=''/>
		)
	}

	renderAggregateTableHeader() {
		const columns = [
			{ title: '', className: 'del-icon', field: null },
			{ title: 'Label', className: 'del-label', field: null },
			{ title: 'Amount', className: 'del-amount', field: null },
			{ title: 'Sent To', className: 'del-sent-to', field: null },
		]
		return (
			<ObjectListHeader columns={columns} ordering=''/>
		)
	}

	setDefaultFilters() {
		const qsFilters = this.getFilters()
		const filters = {
			dates: {
				start: qsFilters.start || moment().startOf('month').format('YYYY-MM-DD'),
				end: qsFilters.end || moment().endOf('month').format('YYYY-MM-DD')
			},
			direction: qsFilters.direction || 'sent',
			aggregate: qsFilters.aggregate || false
		}
		this.handleFilterChange(filters)
	}

	getFilters() {
		const qs = new URLSearchParams(this.props.location.search)
		return {
			dates: {
				start: qs.get('start'),
				end: qs.get('end')
			},
			direction: qs.get('direction'),
			aggregate: qs.get('aggregate') === 'true'
		}
	}

	handleFilterChange(filters) {
		this.getDeliveryHistory(filters)
		const qs = new URLSearchParams(this.props.location.search)
		qs.set('start', filters.dates.start)
		qs.set('end', filters.dates.end)
		qs.set('direction', filters.direction)
		qs.set('aggregate', String(filters.aggregate))
		this.props.history.push({ search: qs.toString() })
	}

	getDeliveryHistory(filters) {
		const range = filters.dates
		let params = {
			start: dateToUTCString(range.start),
			end: dateToUTCString(range.end, true),
			ordering: this.state.ordering,
		}
		if (filters.direction) {
			params.direction = filters.direction
		}
		if (filters.aggregate) {
			params.aggregate = filters.aggregate
		}
		this.props.dispatch(actions.fetchDeliveryHistory(params))
	}

	handleDeleteDelivery(delivery) {
		Modal.confirm({
			title: 'Delete delivery',
			content: "Are you sure you want to delete this delivery?",
			okText: 'Yes, I\'m sure',
			okType: 'danger',
			onOk: () => this.handleConfirmDeleteDelivery(delivery),
			onCancel: () => {}
		})
	}

	handleConfirmDeleteDelivery(delivery) {
		let index = this.getDeliveryIndex(delivery)
		this.props.dispatch(actions.deleteDeliveryHistory(delivery, {}, index))
	}

	getDeliveryIndex(delivery) {
		return this.props.data.findIndex(d => d.id === delivery.id)
	}
}

const mapStateToProps = (state, props) => {
	let ui = state.deliveryHistory.ui,
		teamID = ui.activeUser && state.users.data[ui.activeUser] && state.users.data[ui.activeUser].user.team
	return {
		isFetchingData: ui.isFetchingData,
		teamID,
		data: state.deliveryHistory.data
	}
}

export default connect(mapStateToProps)(DeliveryHistory)