import React, {Fragment} from 'react'
import { connect } from 'react-redux'
import AccountHeader from './AccountHeader'
import AccountBasics from './AccountBasics'
import AccountIntegrations from './AccountIntegrations'
import FactoryOptions from './FactoryOptions'
import AccountTeam from './AccountTeam'
import SectionTabs from '../SectionTabs/SectionTabs'
import './styles/account.css'
import * as actions from "./TeamActions"
import * as userActions from '../AccountMenu/UserActions'
import {isAdmin} from '../../authentication/authentication'

class Account extends React.Component {
	constructor(props) {
		super(props)
		
		this.state = {
			view: 'Account'
		}

		this.updateTimeFormat = this.updateTimeFormat.bind(this)
		this.updateTaskLabelType = this.updateTaskLabelType.bind(this)
		this.updateFactorySetting = this.updateFactorySetting.bind(this)
	}
	
	componentDidMount() {
		this.props.dispatch(userActions.requestRefreshUserAccount(this.props.user.id))
	}
	
	render() {
		const { match, user } = this.props
		const { view } = this.state

		const tabOptions = ['Account']
		if (isAdmin(user)) {
			tabOptions.push('Factory')
		}
		return (
			<div className='settings-page'>
				<SectionTabs 
					activeOption={view} 
					options={tabOptions} 
					onClickTab={view => this.setState({ view })} 
				/>
				<div className='settings-content'>
					{ view === 'Account' &&
						<>
							<AccountHeader />
							<AccountBasics />
							<AccountIntegrations ext={match.params.ext} />
						</>
					}
					{ view === 'Factory' &&
						<>
							<FactoryOptions 
								teamInfo={user.time_format} 
								taskLabelType={user.task_label_type} 
								onChangeTimeFormat={this.updateTimeFormat} 
								onChangeTaskLabelType={this.updateTaskLabelType}
							/>
							<AccountTeam />
						</>
					}
					{ view === 'Network' &&
						<>
							Network Settings
						</>
					}
				</div>
			</div>
		)
	}

	updateTimeFormat(timeFormat){	
		let newFormat = timeFormat === 'n' ? 'm' : 'n'
		this.updateFactorySetting('time_format', newFormat);
	}

	updateTaskLabelType(taskLabelTypeId) {
		this.updateFactorySetting('task_label_type', taskLabelTypeId)
	}

	updateFactorySetting(key, value) {
		if (this.props.isUpdatingSetting) {
			return
		}
		this.props.dispatch(actions.updateLabelType(this.props.user.team, key, value))
	}
}

const mapStateToProps = (state/*, props*/) => {
	const {data, ui} = state.users
	const user = data[ui.activeUser].user
  return {
    users: state.users,
		user: user,
		isUpdatingSetting: ui.isUpdatingSetting,
  }
}

const connected = connect(mapStateToProps)(Account)

export default connected
