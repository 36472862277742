import React from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { Tooltip } from 'antd'

import { formatAmount, getProcessIcon } from "../../utilities/stringutils"
import ObjectListItem from '../ObjectList/ObjectListItem'
import OverflowSafeText from '../OverflowSafeText/OverflowSafeText'
import Img from '../Img/Img'
import './styles/incominglistrow.css'

export default function IncomingListRow({ item, tableProps }) {
	const { task_label, task_id, custom_task_display, amount, sending_team_name, sent_at, process_icon, process_unit } = item
	const { onAccept/*, onReject*/ } = tableProps
	return (
		<ObjectListItem className={'del-row'}>
			<div className="del-icon">
				<Img className="icon-img" src={getProcessIcon(process_icon)} />
			</div>
			<div className='del-col del-task'>
				<Link key={task_id} target="_blank" className="task" to={`/task/${task_id}`}>
					<OverflowSafeText tooltipText={task_label}>
						{custom_task_display || task_label}
					</OverflowSafeText>
				</Link>
			</div>
			<div className='del-col del-amount'>{formatAmount(amount, process_unit)}</div>
			<div className='del-col del-sent-by'>{sending_team_name}</div>
			<div className='del-col del-sent-at'>{moment(sent_at).format('MM/DD/YYYY h:mm a')}</div>
			<div className='del-col del-action'>
				<Tooltip title='Accept'>
					<Img src='checkmark@2x' className='del-accept' height='24px' onClick={() => onAccept(item)} />
				</Tooltip>
				{/* <Img src='delete' className='del-accept' height='14px' onClick={() => onReject(item)} /> */}
			</div>
		</ObjectListItem>
	)
}